/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
    font-family: 'lexend';
    src: url('assets/lexend/fonts/lexend/ttf/Lexend-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}